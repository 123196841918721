import { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Root, Indicator } from "@radix-ui/react-checkbox"

import { CheckIconSvg, MinusIconSvg } from "@ninjaone/icons"
import { typography } from "@ninjaone/tokens/typography"
import { spacing } from "@ninjaone/tokens/spacing"

import Body from "./Typography/Body"

const CHECKBOX_SIZE = 16
const LABEL_GAP_SIZE = spacing[3]

const STATES = {
  PARTIAL: "partial",
}

const radixOverrides = `
  &&& {
    margin: 0;
    box-shadow: none;
  }
`

export const getCheckboxContainerStyles = ({ theme, active, disabled, checked, error }) => {
  const getBackgroundColor = () => {
    if (disabled && checked) return theme.colorBackgroundCtaDisabled
    if (disabled) return theme.colorBackgroundInputDisabled
    if (checked) return theme.colorForegroundSelectedStrongest

    return theme.colorBackground
  }

  const getBorderColor = () => {
    if (disabled && checked) return theme.colorBackgroundCtaDisabled
    if (disabled) return theme.colorBorderWeak
    if (checked) return theme.colorForegroundSelectedStrongest

    return theme.colorBorderStrong
  }

  return `
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${CHECKBOX_SIZE}px;
    height: ${CHECKBOX_SIZE}px;

    &:not([data-state="checked"]) {
      min-width: ${CHECKBOX_SIZE}px;
      min-height: ${CHECKBOX_SIZE}px;
    }

    background: ${getBackgroundColor()};
    border: 1px solid ${getBorderColor()};

    border-radius: 2px;

    ${radixOverrides}

    &:focus-visible {
      outline: 2px solid ${theme.colorForegroundFocus};
    }
  `
}

export const getCheckboxStyles = ({ theme, checked, disabled }) => {
  const getCheckboxFillColor = () => {
    if (disabled) return theme.colorTextDisabled

    return theme.colorTextStrongInverse
  }

  return `
    svg {
      width: 14px;
      fill: ${getCheckboxFillColor()};
    }
  `
}

export const getCheckboxIconByState = ({ state } = {}) => {
  switch (state) {
    case STATES.PARTIAL: {
      return <MinusIconSvg />
    }

    default:
      return <CheckIconSvg />
  }
}

const StyledCheckboxContainer = styled(Root)`
  ${({ theme, active, disabled, checked, error }) =>
    getCheckboxContainerStyles({ theme, active, disabled, checked, error })}
`

const StyledCheckbox = styled(Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, active, disabled, checked, error }) => getCheckboxStyles({ theme, active, disabled, checked, error })}
  ${({ checked }) => `visibility: ${checked ? "visible" : "hidden"}`};
`

const StyledLabelContainer = styled.label`
  display: flex;
  gap: ${spacing[3]};
  align-items: center;
  color: ${({ theme }) => theme.colorTextStrong};
`

const StyledDescriptionContainer = styled.div`
  margin-top: ${spacing[1]};
  margin-left: calc(${LABEL_GAP_SIZE} + ${CHECKBOX_SIZE}px);
`

export default function Checkbox(props) {
  const { id, error, label, description, disabled, fontWeight, checked: state, onChange, ariaLabel, ...rest } = props

  const [internalChecked, setInternalChecked] = useState(false)

  const useInternalChecked = typeof state === "undefined"
  const checked = useInternalChecked ? internalChecked : !!state

  function handleOnCheckboxClick(event) {
    onChange({ event, isChecked: !checked })

    if (useInternalChecked) {
      setInternalChecked(prevState => !prevState)
    }

    event.stopPropagation()
  }

  const _Checkbox = (
    <StyledCheckboxContainer
      onClick={handleOnCheckboxClick}
      {...{ checked, id, ...(ariaLabel ? { "aria-label": ariaLabel } : {}), disabled, ...rest }}
    >
      <StyledCheckbox {...{ checked, error, disabled }}>{getCheckboxIconByState({ state })}</StyledCheckbox>
    </StyledCheckboxContainer>
  )

  return label ? (
    <>
      <StyledLabelContainer data-ninja-checkbox-container="">
        {_Checkbox}

        <Body {...{ fontWeight, fontSize: typography.fontSize.body, as: "span" }}>{label}</Body>
      </StyledLabelContainer>
      {description && (
        <StyledDescriptionContainer>
          <Body color="colorTextWeakest">{description}</Body>
        </StyledDescriptionContainer>
      )}
    </>
  ) : (
    _Checkbox
  )
}

Checkbox.defaultProps = {
  onChange: () => {},
}

Checkbox.propTypes = {
  /**
   * The error state for the component.
   */
  error: PropTypes.string,
  /**
   * The text for the label.
   */
  label: PropTypes.node,
  /**
   * The ARIA label for accessibility.
   */
  ariaLabel: PropTypes.string,
  /**
   * The text for the description.
   */
  description: PropTypes.string,
  /**
   * The disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * The font-weight for the label text.
   */
  fontWeight: PropTypes.number,
  /**
   * The variable that controls the checkbox state.
   */
  checked: PropTypes.oneOf([true, false, "partial"]),
  /**
   * Callback function to be called when the value of the checkbox changes. Returns an object with the event and the isChecked variable.
   * The partial state is considered as checked, so this function will return true for the partial state.
   */
  onChange: PropTypes.func,
}
