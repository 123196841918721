export const SINGLE_SELECT_DISPLAY_TYPE = {
  ICON: "icon",
}

export const maxOptionsToHideCombobox = 5

export const defaultSelectHeight = "38px"

export const selectTriggerMinHeight = "38px"

export const arrowIconWidth = "40px"
export const errorIconWidth = "22px"
export const optionIconWidth = "28px"
export const selectedOptionIconWidth = "24px"

export const comboboxHeight = "46px"

export const defaultOptionHeight = "38px"

export const popoverMaxHeight = "min(var(--popover-available-height, 380px), 380px)"
export const popoverMinWidth = "140px"
export const popoverMaxWidth = "680px"

export const emptyStatePopoverWidth = "298px"
export const emptyStatePopoverHeight = "194px"
