import { useState, useRef, useEffect, useCallback, memo, createContext, useContext } from "react"
import PropTypes from "prop-types"
import { cond, anyPass, omit } from "ramda"

import styled from "@emotion/styled/macro"
import { sizer, useMountedState } from "@ninjaone/utils"

import tokens from "@ninjaone/tokens"

import {
  isEscapeKey,
  isTabKey,
  isDownKey,
  isUpKey,
  isSpaceKey,
  isEnterKey,
  isLeftKey,
  isRightKey,
} from "@ninjaone/webapp/src/js/includes/common/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import StickyPopover from "@ninjaone/webapp/src/js/includes/components/Popover/StickyPopover"

import Text from "../Text"
import { useDynamicHitArea } from "./hooks"

const borderSize = 1
const marginSize = tokens.spacing[1]
const hoverCorridorSize = 20

const getStylesByVariant = ({ theme, variant }) => {
  switch (variant) {
    case "primary":
      return `
        border: ${borderSize}px solid ${theme.colorBorderWeak};
        border-radius: 2px;

        box-shadow: ${theme.elevationWeak};

        font-size: ${tokens.typography.fontSize.headingXs};

        background: ${theme.colorBackground};
        min-width: 200px;
      `
    case "secondary":
      return `
        nav {
          border-radius: 2px;
          padding: ${tokens.spacing[4]};
        }
      `
    default:
      return ``
  }
}

const StyledDropdown = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;

    top: -${hoverCorridorSize / 2}px;
    left: -${hoverCorridorSize / 2}px;

    display: block;

    width: calc(100% + ${hoverCorridorSize}px);
    height: calc(100% + ${hoverCorridorSize}px);

    z-index: -1;
  }

  ${({ dropdownMargin, placement }) =>
    dropdownMargin && isVertical(placement) && `margin-top: ${marginSize}; margin-bottom: ${marginSize}; `}

  ${({ dropdownMargin, placement }) =>
    dropdownMargin && isHorizontal(placement) && `margin-left: ${marginSize}; margin-right: ${marginSize}; `}

  ${({ dropdownWidth }) => dropdownWidth && `width: ${dropdownWidth}px;`}

  nav, ul {
    list-style: none;

    padding: 0;
    margin: 0;
  }

  ${({ theme, variant }) => getStylesByVariant({ theme, variant })}
`

//  only used when a component does not have a matching Provider above it in the tree (root dropdowns)
const defaultContextValue = {
  isRootDropdown: true,
}

export const DropdownContext = createContext(defaultContextValue)

const isVertical = placement => placement === "top" || placement === "bottom"
const isHorizontal = placement => placement === "left" || placement === "right"
const isKeyboardEvent = event => event.keyCode || event.code || event.detail === 0
const isIndexSet = index => index || index === 0

const getNextIndex = (index, length) => {
  const newIndex = index + 1

  return newIndex > length - 1 ? length - 1 : newIndex
}

const getPrevIndex = index => {
  const newIndex = index - 1

  return newIndex < 0 ? 0 : newIndex
}

const getArrowKeyFromPlacement = placement =>
  ({
    top: isUpKey,
    bottom: isDownKey,
    left: isLeftKey,
    right: isRightKey,
  }[placement])

const getInnerFocusableEle = ele => ele?.querySelectorAll("[data-ninja-hover-dropdown-item]")

const HoverDropdown = ({
  dropdownRenderer,
  buttonRenderer,
  route,
  placement = "bottom",
  alignRight,
  dropdownMargin,
  dropdownWidth,
  onMouseEnter,
  loading,
  fullWidth,
  onFocus,
  onCloseRootDropdown,
  variant = "primary",
  onFirstItemTryingToGoUp,
  dynamicHitArea = true,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useMountedState(false)
  const [openedViaKeyboard, setOpenedViaKeyboard] = useState(false)
  const [closedViaMouseLeave, setClosedViaMouseLeave] = useState(false)
  const innerFocusedEleIndex = useRef(null)
  const actualPlacement = useRef(null)
  const wrapperRef = useRef()
  const buttonWrapperRef = useRef()
  const dropdownRef = useRef()
  const { isRootDropdown } = useContext(DropdownContext)

  const getBaseStyle = defaultStyle => ({
    ...omit(["border"], defaultStyle),
    zIndex: 90000, // must be high to beat out other popovers like bootstrap tooltips
  })

  const getTop = ({ wrapperBoundaries, borderSize, buttonWrapperBoundaries, popoverHeight }) => ({
    topPlacement: wrapperBoundaries.top - buttonWrapperBoundaries.height - popoverHeight,
    bottomPlacement: wrapperBoundaries.top - borderSize,
  })

  const getLeft = ({ wrapperBoundaries, buttonWrapperBoundaries, popoverBoundaries }) => ({
    leftPlacement: wrapperBoundaries.left - popoverBoundaries.width,
    rightPlacement: wrapperBoundaries.left + buttonWrapperBoundaries.width,
  })

  const getVerticalPlacement = ({
    popoverHeight,
    popoverBoundaries,
    buttonWrapperBoundaries,
    placement,
    wrapperBoundaries,
  }) => {
    const { topPlacement, bottomPlacement } = getTop({
      wrapperBoundaries,
      borderSize,
      buttonWrapperBoundaries,
      popoverHeight,
    })
    const isOverflowingTop = topPlacement < 0
    const isOverflowingBottom = bottomPlacement + popoverBoundaries.height > window.innerHeight
    const enoughSpaceOnTop = buttonWrapperBoundaries.top > popoverHeight
    const enoughSpaceOnBottom = window.innerHeight - buttonWrapperBoundaries.bottom > popoverHeight

    return placement === "bottom"
      ? isOverflowingBottom && enoughSpaceOnTop
        ? "top"
        : "bottom"
      : isOverflowingTop && enoughSpaceOnBottom
      ? "bottom"
      : "top"
  }

  const getVerticalStyle = ({
    defaultStyle,
    popoverHeight,
    popoverBoundaries,
    buttonWrapperBoundaries,
    wrapperBoundaries,
    finalPlacement,
  }) => {
    const { topPlacement, bottomPlacement } = getTop({
      wrapperBoundaries,
      borderSize,
      buttonWrapperBoundaries,
      popoverHeight,
    })
    const left = alignRight ? wrapperBoundaries.right - popoverBoundaries.width : wrapperBoundaries.left
    const isOverflowingRight = left + popoverBoundaries.width > window.innerWidth

    return {
      ...getBaseStyle(defaultStyle),
      ...(finalPlacement === "bottom" ? { top: bottomPlacement } : { top: topPlacement }),
      ...(isOverflowingRight ? { right: 0, left: null } : { left }),
    }
  }

  const getHorizontalPlacement = ({
    defaultStyle,
    popoverBoundaries,
    buttonWrapperBoundaries,
    wrapperBoundaries,
    placement,
  }) => {
    const { rightPlacement } = getLeft({ wrapperBoundaries, buttonWrapperBoundaries, popoverBoundaries })
    const isOverflowingLeft = wrapperBoundaries.left - buttonWrapperBoundaries.width < 0
    const isOverflowingRight = rightPlacement + popoverBoundaries.width >= window.innerWidth
    const enoughSpaceOnLeft = wrapperBoundaries.left > popoverBoundaries.width
    const enoughSpaceOnRight = buttonWrapperBoundaries.width > popoverBoundaries.width

    return placement === "right"
      ? isOverflowingRight && enoughSpaceOnLeft
        ? "left"
        : "right"
      : isOverflowingLeft && enoughSpaceOnRight
      ? "right"
      : "left"
  }

  const getHorizontalStyle = ({
    defaultStyle,
    popoverBoundaries,
    buttonWrapperBoundaries,
    wrapperBoundaries,
    isOverflowing,
    finalPlacement,
  }) => {
    const { leftPlacement, rightPlacement } = getLeft({
      wrapperBoundaries,
      buttonWrapperBoundaries,
      popoverBoundaries,
    })
    const top = wrapperBoundaries.top - buttonWrapperBoundaries.height - borderSize
    const isOverflowingBottom = top + popoverBoundaries.height >= window.innerHeight

    return {
      ...getBaseStyle(defaultStyle),
      ...(finalPlacement === "right" ? { left: rightPlacement } : { left: leftPlacement }),
      ...(isOverflowingBottom ? { bottom: "0px", top: null } : { top }),
      ...(isOverflowing && { height: "calc(100vh + 1px)", overflowY: "scroll" }),
    }
  }

  const placementMapper = {
    left: getHorizontalPlacement,
    right: getHorizontalPlacement,
    top: getVerticalPlacement,
    bottom: getVerticalPlacement,
  }

  const styleMapper = {
    left: getHorizontalStyle,
    right: getHorizontalStyle,
    top: getVerticalStyle,
    bottom: getVerticalStyle,
  }

  const handleGetPopoverStyle = placement => {
    const buttonWrapperBoundaries = buttonWrapperRef.current.getBoundingClientRect()

    return props => {
      const finalPlacement = placementMapper[placement]({
        ...props,
        buttonWrapperBoundaries,
        placement,
      })

      actualPlacement.current = finalPlacement

      return styleMapper[placement]({
        ...props,
        buttonWrapperBoundaries,
        finalPlacement,
      })
    }
  }

  const getNewIndex = (type, length) => {
    if (type === "prev") {
      return getPrevIndex(innerFocusedEleIndex.current)
    } else if (type === "next") {
      return getNextIndex(innerFocusedEleIndex.current, length)
    } else if (type === "first") {
      return 0
    }
  }

  const handleInnerFocus = useCallback(
    type => {
      const innerFocusableEle = getInnerFocusableEle(dropdownRef.current)

      if (!innerFocusableEle?.length) return

      const newIndex = getNewIndex(type, innerFocusableEle.length)

      if (isIndexSet(newIndex)) {
        innerFocusableEle[newIndex].focus()
      }

      innerFocusedEleIndex.current = newIndex
    },
    [innerFocusedEleIndex],
  )

  const handleClose = useCallback(
    focusOnButton => {
      setTimeout(() => {
        setIsOpen(false)
        setOpenedViaKeyboard(false)
        innerFocusedEleIndex.current = null

        if (focusOnButton) {
          buttonWrapperRef.current?.querySelector("button")?.focus()
        }
      }, 0)
    },
    [setIsOpen],
  )

  const handleTopLevelKeyDown = useCallback(
    event => {
      if (!isOpen) return

      const isArrowKeyFromPlacement = getArrowKeyFromPlacement(actualPlacement.current)

      if (isArrowKeyFromPlacement(event)) {
        event.preventDefault() // prevent scroll
        event.stopPropagation() // other components (like table) may bind into this key

        const userHitArrowAndIsNotFocusedOnDropdown = !isIndexSet(innerFocusedEleIndex.current)

        if (userHitArrowAndIsNotFocusedOnDropdown) {
          handleInnerFocus("first")
          return
        }
      }

      if (isDownKey(event)) {
        event.preventDefault() // prevent scroll
        event.stopPropagation() // other components (like table) may bind into this key

        const focusGotReset = document.activeElement === document.body && innerFocusedEleIndex.current !== null

        if (focusGotReset) {
          innerFocusedEleIndex.current = null // reset back to initial value
          handleInnerFocus("first")
          return
        }
      }

      cond([
        [
          isTabKey,
          () => {
            event.preventDefault() // prevent focus shifting
          },
        ],
        [
          isEscapeKey,
          () => {
            event.stopPropagation() // other components (like table) may bind into this key
            handleClose(true)
          },
        ],
      ])(event)
    },
    [isOpen, handleClose, handleInnerFocus],
  )

  const handleOpen = () => {
    if (disabled) return

    setIsOpen(true)

    document.dispatchEvent(
      new CustomEvent("hoverDropdownOpen", {
        detail: {
          eventCameFromRoot: isRootDropdown,
          targetDropdown: wrapperRef.current,
        },
      }),
    )
  }

  const openAndFocusOnFirstItem = () => {
    setOpenedViaKeyboard(true)
    handleOpen()
  }

  const handleOtherDropdownOnOpen = useCallback(
    event => {
      const { eventCameFromRoot, targetDropdown } = event.detail
      const isSibling = wrapperRef.current.parentNode === targetDropdown.parentNode

      if (isOpen && (eventCameFromRoot || isSibling)) {
        handleClose()
      }
    },
    [handleClose, isOpen],
  )

  const { hitAreaRenderer, hitAreaButtonStyle, hitAreaButtonMouseEvents } = useDynamicHitArea({
    isOpen,
    buttonWrapperRef,
  })
  const enableDynamicHitArea = dynamicHitArea && (placement === "right" || placement === "left")

  useEffect(() => {
    document.addEventListener("hoverDropdownOpen", handleOtherDropdownOnOpen)
    document.body.addEventListener("keydown", handleTopLevelKeyDown)

    return () => {
      document.removeEventListener("hoverDropdownOpen", handleOtherDropdownOnOpen)
      document.body.removeEventListener("keydown", handleTopLevelKeyDown)
    }
  }, [handleTopLevelKeyDown, handleOtherDropdownOnOpen])

  const hasDropdown = !!dropdownRenderer
  const dropdown = (
    <>
      {loading && (
        <Box padding={sizer(2, 4)} pointerEvents="none">
          <Text size="sm" token="general.loading" color="ninjaMedium" />
        </Box>
      )}
      {dropdownRenderer({ tabRoute: route })}
    </>
  )

  return (
    <Box
      data-ninja-hover-dropdown
      position="relative"
      display={fullWidth ? "block" : "inline-block"}
      ref={wrapperRef}
      onFocus={() => {
        setClosedViaMouseLeave(false)
        onFocus?.()
      }}
      onMouseEnter={() => {
        if (!isOpen) {
          handleOpen()
          setClosedViaMouseLeave(false)
        }

        onMouseEnter?.()
      }}
      onMouseLeave={() => {
        if (isOpen && !openedViaKeyboard) {
          handleClose()
          setClosedViaMouseLeave(true)
        }
      }}
      onKeyDown={event => {
        const isNext = event => isOpen && isIndexSet(innerFocusedEleIndex.current) && isDownKey(event)
        const isPrev = event => isOpen && isIndexSet(innerFocusedEleIndex.current) && isUpKey(event)
        const isTryingToCloseWithLeftKey = event => placement === "right" && isLeftKey(event)
        const isTryingToCloseWithRightKey = event => placement === "left" && isRightKey(event)
        const isTryingToClose = event =>
          isOpen && anyPass([isEscapeKey, isTryingToCloseWithLeftKey, isTryingToCloseWithRightKey])(event)
        const stopPropagationToParent = isOpen && anyPass([isTryingToClose, isNext, isPrev])(event)
        const isOnFirstElementTryingToGoUp = innerFocusedEleIndex.current === 0 && isPrev(event)

        if (stopPropagationToParent) {
          event.stopPropagation()
        }

        if (isOnFirstElementTryingToGoUp && onFirstItemTryingToGoUp) {
          innerFocusedEleIndex.current = -1
          onFirstItemTryingToGoUp?.()
          return
        }

        cond([
          [isTryingToClose, () => handleClose(true)],
          [isPrev, () => handleInnerFocus("prev")],
          [isNext, () => handleInnerFocus("next")],
        ])(event)
      }}
    >
      <div
        data-ninja-hover-dropdown-button
        aria-expanded={isOpen}
        ref={buttonWrapperRef}
        onClick={() => {
          isOpen ? handleClose() : handleOpen()
        }}
        onKeyDown={event => {
          const isArrowKeyFromPlacement = getArrowKeyFromPlacement(placement)
          const isTryingToOpenWithArrow = event => !isOpen && isArrowKeyFromPlacement(event)

          cond([
            [
              isTryingToOpenWithArrow,
              () => {
                event.stopPropagation() // prevent handleTopLevelKeyDown from running on body
                openAndFocusOnFirstItem()
              },
            ],
            [
              anyPass([isEnterKey, isSpaceKey]),
              () => {
                event.preventDefault() // prevent click handler from running
                openAndFocusOnFirstItem()
              },
            ],
          ])(event)
        }}
        {...(enableDynamicHitArea && {
          style: hitAreaButtonStyle,
          ...hitAreaButtonMouseEvents,
        })}
      >
        {buttonRenderer({ isOpen, closedViaMouseLeave })}
      </div>

      {hasDropdown && isOpen && (
        <StickyPopover
          style={handleGetPopoverStyle(placement)}
          innerStyle={({ defaultInnerStyle }) => omit(["backgroundColor", "overflowY", "maxHeight"], defaultInnerStyle)}
          showPopoverArrow={false}
          trigger={false}
          onVisible={() => {
            if (isOpen && openedViaKeyboard && !isIndexSet(innerFocusedEleIndex.current)) {
              handleInnerFocus("first")
            }
          }}
          onClickOutside={event => {
            const clickedOwnOrNestedButton = !!event.target.closest("[data-ninja-hover-dropdown]")
            const clickedWithinDropdown = !!event.target.closest("[data-ninja-hover-dropdown-container]")

            if (isOpen && !clickedOwnOrNestedButton && !clickedWithinDropdown && !isKeyboardEvent(event)) {
              handleClose()
            }
          }}
          {...(enableDynamicHitArea && {
            bottomElementRenderer: ({ popoverBoundaries }) =>
              hitAreaRenderer({ popoverBoundaries, isLeftPlacement: actualPlacement.current === "left" }),
          })}
        >
          <StyledDropdown
            data-ninja-hover-dropdown-container
            data-testid="hover-dropdown-container"
            ref={dropdownRef}
            dropdownMargin={dropdownMargin}
            placement={placement}
            variant={variant}
            dropdownWidth={dropdownWidth}
          >
            {isRootDropdown ? (
              <DropdownContext.Provider
                value={{
                  closeRootDropdown: () => {
                    handleClose()
                    onCloseRootDropdown?.()
                  },
                  isRootDropdown: false,
                }}
              >
                {dropdown}
              </DropdownContext.Provider>
            ) : (
              dropdown
            )}
          </StyledDropdown>
        </StickyPopover>
      )}
    </Box>
  )
}

HoverDropdown.propTypes = {
  dropdownRenderer: PropTypes.func.isRequired,
  buttonRenderer: PropTypes.func.isRequired,
  route: PropTypes.string,
  placement: PropTypes.string,
  alignRight: PropTypes.bool,
  dropdownMargin: PropTypes.bool,
  dropdownWidth: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onCloseRootDropdown: PropTypes.func,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  dynamicHitArea: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default memo(HoverDropdown)
