import { compose, join, pluck } from "ramda"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Text, Tooltip } from "@ninjaone/components"
import { CaretDownIcon, CloseIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import { localized, ellipsis, isNotNilOrEmpty } from "@ninjaone/webapp/src/js/includes/common/utils"
import { StyledSpan } from "@ninjaone/webapp/src/js/includes/components/Styled"

const StyledButtonContainer = styled.div`
  border: ${({ theme, hasFilter }) => `1px solid ${hasFilter ? theme.colorBorderStrong : theme.colorBorderWeak}`};
  background-color: transparent;
  border-radius: ${tokens.borderRadius[1]};
  display: flex;
  width: fit-content;
  align-items: center;
  height: ${({ height }) => height};
`
const StyledTableFilterButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${tokens.spacing[2]} ${({ withRemove }) => (withRemove ? tokens.spacing[1] : tokens.spacing[3])}
    ${tokens.spacing[2]} ${tokens.spacing[3]};
  border-radius: ${tokens.borderRadius[1]};
  border: none;
  background-color: transparent;
  outline: none;
  z-index: 0;
  &:focus-visible {
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.colorForegroundFocus}`};
  }

  &:not([data-disabled]) {
    cursor: pointer;
  }

  &[data-disabled] {
    background-color: ${({ theme }) => theme.colorBackground};
    color: ${({ theme }) => theme.colorTextDisabled};
  }

  &:not([data-disabled]) {
    cursor: pointer;
  }

  &[data-disabled] {
    background-color: ${({ theme }) => theme.colorBackground};
    color: ${({ theme }) => theme.colorTextDisabled};
  }
`

const StyledTableFilterDiv = StyledTableFilterButton.withComponent("div")

const StyledCloseButton = styled.button`
  padding: 0 ${tokens.spacing[3]};
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  border-radius: 0 ${tokens.borderRadius[1]} ${tokens.borderRadius[1]} 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &[data-disabled] {
    pointer-events: none; // prevent hover styles
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:focus-visible {
    border-radius: ${tokens.borderRadius[1]};
    background-color: ${({ theme }) => theme.colorForegroundHover};
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.colorForegroundFocus}`};
  }
`
const StyledCloseButtonDiv = StyledCloseButton.withComponent("div")

const StyledPill = styled.div`
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
  color: ${({ theme }) => theme.colorTextStrong};
  padding: 1px 9px;
  border-radius: ${tokens.borderRadius[2]};
  margin-left: ${tokens.spacing[2]};
  font-weight: ${tokens.typography.fontWeight.semiBold};
  font-size: ${tokens.typography.fontSize.bodyXs};

  [data-disabled="true"] & {
    color: ${({ theme }) => theme.colorTextDisabled};
  }
`

const MAX_FILTER_LENGTH = 30

const TableFilterButton = ({
  filters,
  onRemove,
  labelToken,
  hideTooltip,
  handleClick,
  isRemovable,
  renderAsDiv,
  height = "38px",
  alwaysShowRemove,
  hideLabelOnSingleSelect,
  labelSelectorKey = "label",
  showSingleSelectValue = true,
  truncateSingleSelectValue = true,
  secondaryFilterValueCounter,
  disabled,
  filterButtonRef,
}) => {
  const withRemove = alwaysShowRemove || (isRemovable && !!filters?.length)
  let withTooltip = filters?.length > 1 && !hideTooltip

  const StyledTableFilterElement = renderAsDiv ? StyledTableFilterDiv : StyledTableFilterButton
  const StyledCloseButtonElement = renderAsDiv ? StyledCloseButtonDiv : StyledCloseButton

  const getLabelContent = () => {
    if (!filters?.length) {
      return <Text size="sm" token={labelToken} color={disabled ? "colorTextDisabled" : "colorTextStrong"} />
    } else if (filters.length === 1 && showSingleSelectValue) {
      const label = localized(labelToken) + ":"
      let filterValue = filters[0][labelSelectorKey]?.trim() ?? ""
      if ((label + filterValue).length > MAX_FILTER_LENGTH && truncateSingleSelectValue) {
        filterValue = ellipsis(filterValue, MAX_FILTER_LENGTH - label.length)
        withTooltip = true
      }
      return (
        <>
          {!hideLabelOnSingleSelect && (
            <StyledSpan marginRight={sizer(1)}>
              <Text size="sm" semiBold color={disabled ? "colorTextDisabled" : "colorTextStrong"}>
                {label}
              </Text>
            </StyledSpan>
          )}
          <Text size="sm" color="colorTextStrong">
            {filterValue}
          </Text>
          {isNotNilOrEmpty(secondaryFilterValueCounter) && <StyledPill>{secondaryFilterValueCounter}</StyledPill>}
        </>
      )
    } else {
      return (
        <>
          <Text size="sm" semiBold token={labelToken} color={disabled ? "colorTextDisabled" : "colorTextStrong"} />
          <StyledPill>{filters.length}</StyledPill>
        </>
      )
    }
  }

  const getTooltipText = () => {
    const text = compose(join(", "), pluck(labelSelectorKey))(filters)

    return `${localized(labelToken)}: ${text}`
  }

  const _Button = (
    <StyledButtonContainer {...{ hasFilter: filters?.length, height }}>
      <StyledTableFilterElement
        {...{
          withRemove,
          id: `${labelToken}-dropdown-button`,
          onClick: handleClick,
          "aria-haspopup": "menu",
          ...(disabled && { "data-disabled": true, disabled }),
          ref: filterButtonRef,
        }}
      >
        {getLabelContent()}
        {!withRemove && (
          <StyledSpan marginLeft={sizer(4)}>
            <CaretDownIcon fixedWidth={false} color={disabled ? "colorTextDisabled" : "colorTextWeakest"} />
          </StyledSpan>
        )}
      </StyledTableFilterElement>
      {withRemove && (
        <StyledCloseButtonElement
          onClick={e => {
            onRemove?.()
          }}
          onPointerDown={e => e.stopPropagation()} // prevents react listbox from opening
          id={`${labelToken}-dropdown-button-remove-button`}
          {...(disabled && {
            "data-disabled": disabled,
            disabled,
          })}
        >
          <CloseIcon fixedWidth={false} color={disabled ? "colorTextDisabled" : "colorTextWeakest"} />
        </StyledCloseButtonElement>
      )}
    </StyledButtonContainer>
  )

  return withTooltip && !disabled ? <Tooltip label={getTooltipText()}>{_Button}</Tooltip> : _Button
}

TableFilterButton.propTypes = {
  labelToken: PropTypes.string.isRequired,
  filters: PropTypes.array,
  renderAsDiv: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  handleClick: PropTypes.func,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  alwaysShowRemove: PropTypes.bool,
  hideLabelOnSingleSelect: PropTypes.bool,
  showSingleSelectValue: PropTypes.bool,
  secondaryFilterValueCounter: PropTypes.number,
}

export default TableFilterButton
