import styled from "@emotion/styled"
import { SelectArrow, useSelectContext } from "@ariakit/react"

import tokens from "@ninjaone/tokens"
import { ChevronDownLightIcon, ChevronUpLightIcon } from "@ninjaone/icons"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { ErrorIconTooltip } from "../../IconTooltips"

const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing[2]};

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 14px;
    height: 14px;

    border: 0;
    border-radius: ${tokens.borderRadius[2]};

    background: none;

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

export function SelectActions({ ariaId, errorMessage, disabled }) {
  const selectStore = useSelectContext()
  const isSelectOpen = selectStore.useState("open")

  return (
    <StyledActionsContainer>
      {errorMessage && !disabled && (
        <ErrorIconTooltip
          {...{
            ariaError: `${localized("Error")} ${errorMessage}`,
            ariaId,
            ariaLive: "assertive",
            error: errorMessage,
            tooltipAlignment: "end",
            triggerAsChild: false,
          }}
        />
      )}

      <SelectArrow
        {...{ render: isSelectOpen ? ChevronUpLightIcon : ChevronDownLightIcon, color: "colorTextWeakest" }}
      />
    </StyledActionsContainer>
  )
}
