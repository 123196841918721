import PropTypes from "prop-types"
import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"

import { getColorFromProps, getTextSize } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import tokens from "@ninjaone/tokens"

import Tooltip from "./Tooltip"

const nonForwardingProps = new Set(["color", "fontWeight", "type"])

const getStylesFromProps = ({ color, lineHeight, size, theme, type }) => {
  const fallbackColor = !color ? `color: ${theme.colorTextStrong};` : ""
  switch (type) {
    case "headingXl": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.headingXl};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    case "headingL": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.headingL};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    case "headingM": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.headingM};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    case "headingS": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.headingS};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    case "headingXs": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.headingXs};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    case "body": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.body};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    case "bodyXs": {
      return `
          margin: 0;
          font-size: ${tokens.typography.fontSize.bodyXs};
          line-height: ${tokens.typography.lineHeight};
          ${fallbackColor}
        `
    }
    default:
      return `
          font-size: ${getTextSize(size)};
          line-height: ${lineHeight ?? tokens.typography.lineHeight};
        `
  }
}

export const preventNativeTooltipInSafariStyles = `
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    line-height: 0;
  }
`

const StyledText = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) && !nonForwardingProps.has(prop),
})`
  color: ${getColorFromProps()};
  ${({ color, lineHeight, size, theme, type }) => getStylesFromProps({ color, lineHeight, size, theme, type })}
  font-family: ${({ code }) => (code ? tokens.typography.fontFamily.code : "inherit")};
  font-weight: ${({ bold, semiBold, extraBold, fontWeight }) => {
    if (fontWeight) return fontWeight
    if (extraBold) return tokens.typography.fontWeight.semiBold // maintain until instances using this prop have been updated
    if (semiBold) return tokens.typography.fontWeight.semiBold // maintain until instances using this prop have been updated
    if (bold) return tokens.typography.fontWeight.medium // maintain until instances using this prop have been updated
    return tokens.typography.fontWeight.regular
  }};
  user-select: auto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}`};
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}`};

  color: ${getColorFromProps()};

  ${({ textWrap, textWrapLineLimit, italic, wordWrap, width, uppercase }) => `
    ${!textWrap ? `white-space: nowrap` : ""};
    ${!textWrap || textWrapLineLimit ? `overflow: hidden; text-overflow: ellipsis` : ""};
    ${
      textWrap && textWrapLineLimit
        ? `display: -webkit-box; -webkit-line-clamp: ${textWrapLineLimit}; -webkit-box-orient: vertical`
        : ""
    };
    ${italic ? "font-style: italic" : ""};
    ${uppercase ? "text-transform: uppercase" : ""};

    ${wordWrap ? `word-wrap: ${wordWrap};` : ""};
    ${width ? `width: ${width};` : ""};
  `};

  ${({ enableTooltip }) => enableTooltip && preventNativeTooltipInSafariStyles}
`
export default function Text({
  as,
  children,
  code,
  fontWeight,
  token,
  tokenArgs,
  size = "md",
  bold,
  semiBold,
  extraBold,
  textWrap,
  wordWrap,
  width,
  textWrapLineLimit,
  lineHeight,
  color,
  id,
  italic,
  tooltipPosition,
  tooltipZIndex,
  tooltipText,
  hideTooltip,
  marginTop,
  marginBottom,
  type,
  uppercase,
  visuallyHidden,
  forceRefreshOnLoad,
  "data-testid": dataTestid = "styled-text-div",
}) {
  const textNodeEl = useRef(null)
  const textContainerEl = useRef(null)
  const text = token ? localized(token, tokenArgs) : children

  const [textEllipsis, setTextEllipsis] = useState(false)

  const overflowVerification = useCallback(() => {
    const containerSize =
      (textWrap
        ? textContainerEl?.current?.getBoundingClientRect().height
        : textContainerEl?.current?.getBoundingClientRect().width) ?? 0
    const textSize =
      (textWrap
        ? textNodeEl?.current?.getBoundingClientRect().height
        : textNodeEl?.current?.getBoundingClientRect().width) ?? 0

    setTextEllipsis(containerSize < textSize)
  }, [textWrap])

  useLayoutEffect(() => {
    forceRefreshOnLoad ? setTimeout(overflowVerification, 0) : overflowVerification()
  }, [forceRefreshOnLoad, overflowVerification])

  const textNode = visuallyHidden ? (
    <VisuallyHidden {...id}>{text}</VisuallyHidden>
  ) : (
    <span ref={textNodeEl}>{text}</span>
  )

  const enableTooltip = (!textWrap || textWrapLineLimit) && textEllipsis

  return (
    <StyledText
      data-testid={dataTestid}
      {...{
        size,
        bold,
        semiBold,
        extraBold,
        textWrap,
        color,
        lineHeight,
        textWrapLineLimit,
        id,
        italic,
        wordWrap,
        width,
        as,
        type,
        fontWeight,
        code,
        uppercase,
        marginTop,
        marginBottom,
        enableTooltip,
      }}
      ref={textContainerEl}
      onMouseEnter={overflowVerification}
    >
      {enableTooltip ? (
        <Tooltip label={tooltipText || text} contentZIndex={tooltipZIndex}>
          {textNode}
        </Tooltip>
      ) : (
        textNode
      )}
    </StyledText>
  )
}

Text.propTypes = {
  /**
   * The semantic HTML element the component will render as.
   */
  as: PropTypes.oneOf(["dt", "dd", "h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "strong", "legend", "code"]),
  children: PropTypes.node,
  /**
   * Sets the font family for code blocks.
   */
  code: PropTypes.bool,
  /**
   * The font weight of the component.
   */
  fontWeight: PropTypes.oneOf([400, 500, 600]),
  /**
   * The id of the commponent.
   */
  id: PropTypes.string,
  /**
   * Sets the font style to italic.
   */
  italic: PropTypes.bool,
  token: PropTypes.string,
  tokenArgs: PropTypes.object,
  /**
   * Deprecated - Please use type instead
   * @deprecated - use type instead
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  /**
   * Deprecated - Please use type instead
   * @deprecated - use type instead
   */
  lineHeight: PropTypes.any,
  /**
   * Deprecated - please use `fontWeight` instead
   * @deprecated - use fontWeight instead
   */
  bold: PropTypes.bool,
  /**
   * Deprecated - please use `fontWeight` instead
   * @deprecated - use fontWeight instead
   */
  semiBold: PropTypes.bool,
  /**
   * Deprecated - please use `fontWeight` instead
   * @deprecated - use fontWeight instead
   */
  extraBold: PropTypes.bool,
  textWrap: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipPosition: PropTypes.func,
  hideTooltip: PropTypes.bool,
  /**
   * Determines the font size and light height for the component.
   */
  type: PropTypes.oneOf(["headingXl", "headingL", "headingM", "headingS", "headingXs", "body", "bodyXs"]),
  /**
   * Transforms the text to uppercase.
   */
  uppercase: PropTypes.bool,
  /**
   * Visually hide the component.
   */
  visuallyHidden: PropTypes.bool,
  /**
   * Overrides the tooltip text with the specified string.
   */
  tooltipText: PropTypes.string,
  /**
   * CSS word-wrap property that allows long words to be able to break and wrap onto the next line
   */
  wordWrap: PropTypes.string,
}
