import { useContext } from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled"
import { css } from "@emotion/react"

import tokens from "@ninjaone/tokens"
import { AngleRightIcon } from "@ninjaone/icons"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { DropdownContext } from "./HoverDropdown"
import useAccessibleActionIcon from "../Hooks/useAccessibleActionIcon"

const getStylesByVariant = ({ variant, theme }) => {
  switch (variant) {
    case "default":
      return `
        padding: 0 ${tokens.spacing[3]};
        height: 38px;

        background-color: ${theme.colorBackground};

        && {
          color: ${theme.colorTextStrong};
        }

        button:focus > &,
        &.active,
        &:hover,
        &:focus,
        &:focus-within {
          background-color: ${theme.colorForegroundHover};
        }

        &[disabled] {
          color: ${theme.colorBackgroundCtaDisabled};
        }
      `
    case "branded":
      return `
        height: 28px;

        padding-left: ${tokens.spacing[2]};
        padding-right: ${tokens.spacing[2]};

        && {
          color: ${theme.colorThemeText};
        }

        &:hover {
          background-color: ${theme.colorThemeBackgroundHover};
        }

        &.active {
          background-color: ${theme.colorThemeBackgroundSelected};
        }

        button:focus-visible > &,
        &:focus-visible {
          background-color: ${theme.colorThemeBackgroundSelected};

          outline: 2px solid ${theme.colorForegroundFocus};
          outline-offset: -2px;
        }
      `
    default:
      return ``
  }
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;

  border-radius: 2px;

  background: transparent;
  border: none;
  cursor: pointer;

  width: 100%;

  font-size: ${tokens.typography.fontSize.headingXs};

  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
  }

  &:not(:focus-visible):not(:focus-within):not(:hover) {
    .action-icons {
      visibility: hidden;
    }
  }

  ${({ variant, theme }) => getStylesByVariant({ variant, theme })}

  &[disabled] {
    cursor: not-allowed;
    background-color: transparent;
  }
`

const StyledAnchor = StyledButton.withComponent("a")

const actionIconWithHoverStyle = theme => css`
  &:hover {
    svg {
      color: ${theme.colorForegroundFocus};
    }
  }
`

const StyledDivider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colorBorderWeakest};
`

export default function HoverDropdownItem({
  plainText,
  route,
  textRenderer,
  token,
  showArrow,
  isOpen,
  onClick,
  rel,
  isDropdownButton,
  splitBefore,
  disabled,
  actionIcons,
  variant = "default",
  showActionIconOnHover,
  actionIconStyle,
  actionIconWrapperStyle,
  strictActiveCheck = false,
  usesActiveClass = true,
  closeOnIconClick = false,
  active: controlledActive,
  id,
}) {
  const isActive =
    controlledActive || (strictActiveCheck ? window.location.hash === route : window.location.hash.startsWith(route))

  const isAnchor = !!route

  const StyledItem = isAnchor ? StyledAnchor : StyledButton
  const { closeRootDropdown } = useContext(DropdownContext)

  const handleCloseRootDropdown = () => {
    !isDropdownButton && closeRootDropdown?.()
  }

  const { actionIconsRenderer, parentElementProps } = useAccessibleActionIcon({
    actionIcons,
    onIconClick: () => {
      closeOnIconClick && handleCloseRootDropdown()
    },
    actionIconStyle: variant === "default" ? [actionIconStyle, actionIconWithHoverStyle] : actionIconStyle,
    showActionIconOnHover,
  })

  return (
    <>
      {splitBefore && <StyledDivider />}
      <StyledItem
        data-ninja-hover-dropdown-item
        data-testid={id}
        className={(usesActiveClass && isActive) || isOpen ? "active" : ""}
        disabled={disabled}
        onClick={event => {
          onClick?.(event)
          handleCloseRootDropdown()
        }}
        variant={variant}
        {...(isAnchor && { href: route, rel })}
        {...parentElementProps}
      >
        {token ? localized(token) : plainText || textRenderer?.()}
        {showArrow && <AngleRightIcon size="sm" color="colorTextWeakest" />}
        {actionIconsRenderer && (
          <div css={actionIconWrapperStyle} className="action-icons">
            {actionIconsRenderer()}
          </div>
        )}
      </StyledItem>
    </>
  )
}

HoverDropdownItem.propTypes = {
  active: PropTypes.bool,
  plainText: PropTypes.string,
  route: PropTypes.string,
  textRenderer: PropTypes.func,
  token: PropTypes.string,
  showArrow: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  isDropdownButton: PropTypes.bool,
  splitBefore: PropTypes.bool,
  disabled: PropTypes.bool,
  showActionIconOnHover: PropTypes.bool,
  variant: PropTypes.string,
  usesActiveClass: PropTypes.bool,
  strictActiveCheck: PropTypes.bool,
  id: PropTypes.string,
}
