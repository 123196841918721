import MomentLocaleUtils from "react-day-picker/moment"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Flex, Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import tokens from "@ninjaone/tokens"
import Select from "../../../Selects/Select"
import Body from "../../../Typography/Body"

const StyleFlex = styled(Flex)`
  button[data-ninja-select-trigger] {
    height: 32px;
    padding: 0 ${tokens.spacing[1]};
  }

  & > div:nth-of-type(1) {
    max-width: 110px;
  }

  & > div:nth-of-type(2) {
    max-width: 72px;
  }
`

const DatePickerDropdownNavigation = ({
  date,
  excludeYear = false,
  locale,
  onChange,
  yearMinOffset,
  yearMaxOffset,
}) => {
  const currentYear = new Date().getFullYear()
  const fromDate = new Date(currentYear - yearMinOffset, 0)
  const toDate = new Date(currentYear + yearMaxOffset, 11)
  const months = MomentLocaleUtils.getMonths(locale)

  const years = Array.from(
    { length: toDate.getFullYear() - fromDate.getFullYear() + 1 },
    (_, i) => fromDate.getFullYear() + i,
  )

  const yearOptions = years.map((year, i) => ({
    value: year,
    labelText: `${year}`,
  }))

  // i is incremented by 1 due to Select returning 0 as a value as undefined
  const monthOptions = months.map((month, i) => ({
    value: i + 1,
    labelText: month,
  }))

  const handleMonthChange = value => {
    // value is decremented by 1 due to increment of i in monthOptions
    onChange(new Date(date.getFullYear(), value - 1))
  }
  const handleYearChange = value => {
    onChange(new Date(value, date.getMonth()))
  }

  const YearDropdown = ({ disabled }) => (
    <Select
      value={date.getFullYear()}
      labelId="year"
      labelRenderer={() => <Body>{date.getFullYear()}</Body>}
      listboxMinWidth={72}
      id="year"
      onChange={handleYearChange}
      options={yearOptions}
      triggerAriaLabel={localized("Year")}
      triggerMinWidth={72}
      {...{ disabled }}
    />
  )

  return (
    <Box className="DayPicker-Caption">
      <StyleFlex gap={tokens.spacing[1]}>
        <VisuallyHidden>
          <label htmlFor="month">{localized("Month")}</label>
        </VisuallyHidden>

        <Select
          value={date.getMonth() + 1} // incremented by 1 due to i being incremented in monthOptions
          labelId="month"
          labelRenderer={() => <Body>{monthOptions[date.getMonth()].labelText}</Body>}
          listboxMinWidth={120}
          id="month"
          onChange={handleMonthChange}
          options={monthOptions}
          triggerAriaLabel={localized("Month")}
          triggerMinWidth={110}
        />

        <VisuallyHidden>
          <label htmlFor="year">{localized("Year")}</label>
        </VisuallyHidden>

        {excludeYear ? (
          <VisuallyHidden>
            <YearDropdown disabled />
          </VisuallyHidden>
        ) : (
          <YearDropdown />
        )}
      </StyleFlex>
    </Box>
  )
}

DatePickerDropdownNavigation.propTypes = {
  /**
   * Internal prop provided by `react-day-picker`.
   */
  date: PropTypes.instanceOf(Date),
  /**
   * Provides the option to toggle the display of the year dropdown.
   */
  excludeYear: PropTypes.bool,
  /**
   * The locale for the component. Defaults to browser's locale.
   */
  locale: PropTypes.string,
  /**
   * Internal event handler run when the year or month changes to update the
   * calendar. Prop provided by the `DatePicker` component.
   */
  onChange: PropTypes.func,
  /**
   * The offset to subtract from the current year.
   */
  yearMinOffset: PropTypes.number,
  /**
   * The offset to add to the current year.
   */
  yearMaxOffset: PropTypes.number,
}

export default DatePickerDropdownNavigation
